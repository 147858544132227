import '../styles/App.css';
import React from "react";
import metadata from "../utils/grinders-tokens.json"; 

const Gifs = () => {
    const oslink = 'https://opensea.io/assets/0x953c257af523f4275d06cc3597a2a3d9b737c4b0/';
    const images = [];
    const buildGifs = () =>{
       for (const token of metadata.tokens){
                images.push( 
                    <div className="gif-box">
                        <img className="grinder-gif" src={token.gif} alt="gif" />
                        <a href={oslink+token.token}>{token.name}</a>
                        <br />palette: {token.attributes[0].value}
                    </div>
                );
        }
    }
    buildGifs();
    return (               
        <div className="gif-container">
            {images}
        </div>
    );
};

export default Gifs;

