import './styles/App.css';
import React from "react";
import Gifs from './components/gifs';
//import WalletUi from './components/WalletUi';

const OPENSEA_LINK = 'https://opensea.io/collection/grinders-by-andrew-benson';

const App = () => {
  //const CONTRACT_ADDRESS = "0x953C257aF523F4275D06Cc3597A2A3D9b737c4B0";
  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <div className="header gradient-text">Grinders</div>
          <div className="sub-text">
            A series of 100 Animated Paintings 
            <br />by Andrew Benson
            <br /><a href={OPENSEA_LINK}>View on OpenSea</a>
            <br /><a href="https://zora.co/collections/0x953C257aF523F4275D06Cc3597A2A3D9b737c4B0">View on Zora</a>
            <br /><a href="https://etherscan.io/address/0x953c257af523f4275d06cc3597a2a3d9b737c4b0">View Contract on Etherscan</a>
          </div>
        </div>
        <Gifs />
        </div>
        <div className="footer-container">
        </div>
    </div>
  );
};

export default App;